(function (framework, $, window, document, undefined) {
    "use strict";

    $(function () {
        $('.flexslider').flexslider({
            animation: "slide",
            controlNav: "thumbnails",
            prevText: "",
            nextText: ""
        });

        $('.slider-2-container').backstretch([
          "/img/slider/3.jpg", "/img/slider/1.jpg", "/img/slider/2.jpg", "/img/slider/4.jpg"
        ], { duration: 9000, fade: 750 });
    });
})(window.framework = window.framework || {}, window.jQuery, window, document);